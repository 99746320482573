.multiple-locations-callout {
	background: $cool-white;
	padding: 48px 0 16px;

	.bg-wrapper {
		@include desktop-grid-max-width();
		max-width: 1000px;
	}

	.btn-container {
		margin: 24px 0 0;
		text-align: center;

		@include break-min($large1) {
			margin: 34px 0 0;
		}
	}

	.content {
		text-align: center;

		.title {
			> *:first-child {
				color: $blue;
				@include font-size(3.6);
				font-weight: $bold-weight;
				line-height: lh(46, 36);
				letter-spacing: -0.8px;
				margin: 0 0 16px 0;
			}
		}

		.subtext p {
			@include font-size(1.8);
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			margin: 0 0 24px 0;
		}
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		@include break-min($large1) {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}

		li {
			margin: 0 0 48px 0;

			@include break-min($large1) {
				width: 33%;
			}

			.img-container {
				border-radius: 50%;
				overflow: hidden;
				position: relative;
				height: 160px;
				margin: 0 auto 40px;
				width: 160px;

				@include break-min($large1) {
					height: 200px;
					width: 200px;
				}

				img {
					@include absolute-center();
					object-fit: cover;
					height: 100%;
					width: 100%;
				}
			}

			.address-detail {
				text-align: center;

				.title {
					> *:first-child {
						@include font-size(2.4);
						color: $grey;
						font-weight: $bold-weight;
						line-height: lh(34, 24);
						letter-spacing: -0.5px;
						margin: 0 0 8px 0;
					}
				}

				p {
					@include font-size(1.8);
					line-height: lh(28, 18);
					letter-spacing: -0.5px;
					margin: 0 0 8px 0;
				}
			}
		}
	}

	&.product-landing {
		background: $white;
		margin: 0 0 64px;

		ul {
			li {
				margin: 0 0 30px;

				@include break-min($large1) {
					margin: 0;
				}
			}
		}

		.img-container {
			margin: 0 auto 15px;
		}

		.address-detail {
			.title {
				> *:first-child {
					@include font-size(2);
					font-weight: $bold-weight;
					line-height: lh(28, 20);
					color: $blue;

					@include break-min($large1) {
						@include font-size(2.4);
						line-height: lh(34, 24);
					}
				}
			}

			p {
				display: none;
			}
		}
	}
}
