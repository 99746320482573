// sass-lint:disable max-file-line-count, no-qualifying-elements

@import "nav-toggle";
@import "stories-feature";

.main-nav {
	margin: 0;
	padding: 0;
	display: none;

	@media only screen and (max-width: $mobile) {
		background: $white;
		height: 100vh;
		left: 0;
		margin: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}

	@include break-min($large1) {
		margin: 0 0 0 auto;
		@include flex(0 0 auto);
		@include flex-wrap(nowrap);
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-end);
	}

	li {
		@include font-size(1.5);
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: 1.2;
		z-index: 200;

		a {
			padding: 0;
			border: 0;

			&.current {
				color: $red;
			}
		}

		span:not(.icon-user-active) {
			a {
				@include link-underline();
			}
		}

		&.mobile-only {
			display: none;

			@media only screen and (max-width: $mobile) {
				display: inline-block;
			}
		}

		a:not(.order-online) {
			@include link-underline();
		}

		@media only screen and (min-width: $mobile) {
			display: inline-block;

			& > a {
				&.current {
					text-decoration: underline;
				}
			}
		}

		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 0;

			&.additional {
				border-top: 2px solid $white-smoke;
				padding-top: 10px;
			}

			&.active {
				.icon-arrow-down {
					&::before {
						@include transform(rotate(180deg));
						// margin-top: 8px;
					}
				}

				.subnav {
					display: block;
				}

				& > a {
					& > span {
						&:first-child {
							text-decoration: underline;
							display: inline-block;
						}
					}

					&.current {
						& > span {
							&:first-child {
								display: inline-block;
								border-bottom: 2px dotted transparent;
							}
						}
					}
				}
			}

			a:not(.btn-round) {
				@include font-size(2.3);
				display: block;
				letter-spacing: 0;
				line-height: 1.2;
				padding: 20px 26px;

				.subnav {
					li {

						a {
							font-size: 20px !important;
							padding: 13px 38px !important;
						}
					}
				}

				&:hover {
					border-color: transparent;
				}
			}
		}

	}

	@media only screen and (max-width: $mobile) {
		& > ul {
			display: block;

			li {
				border-bottom: 1px solid $concrete;
				width: 100%;
			}
		}
	}

	@include break-min($large1) {
		> ul {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			z-index: 0;
			@include flex-wrap(nowrap);
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);

			li {
				padding: 5px 14px;
			}
		}
	}

	.icon-arrow-down {
		@include font-size(1.4);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: right;
		padding: 0 19px 0 0;

		&::before {
			display: inline-block;
			line-height: 4.3;
			@include transform(rotate(270deg));
		}

		@media only screen and (min-width: $lrg-tablet) {
			display: none;
		}
	}

	&.active {
		> *,
		.cfa-one {
			display: none;
		}

		> .search {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			padding: 0 70px 0 110px;
			@include flexbox();
			@include align-items(center);
			@include justify-content(stretch);
		}
	}

	ul,
	li {
		margin: 0;
		padding: 0;
	}

	> ul {
		@media only screen and (max-width: $mobile) {
			margin-top: 75px;
			height: calc(38vh + 195px);
			overflow-y: scroll;
			padding-top: 0 !important;

			> li > a {
				position: relative;
			}
		}
	}

	.search {
		z-index: 200;
		position: relative;
		// order: 7;
		@media only screen and (max-width: $mobile) {
			z-index: 300;
			// order: 1;
			padding: 13px 10px 0 10px;
			text-align: center;
			position: absolute;
			top: $nav-base-height;
			right: 0;
			left: 0;
		}

		.icon-close {
			display: none;
		}

		&.active {
			width: 100%;
			background-color: $white;

			@include break-min($large1) {
				.icon-close {
					color: $red;
					display: inline-block;
					position: absolute;
					right: 0;
					margin: 0;
					padding: 0;
					width: 64px;
					top: 0;
					height: 64px;

					&::before {
						content: none;
					}

					// burger
					&::after {
						content: "";
						background-image: url("img/icon_close.svg");
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 16px;
						height: 16px;
						transition: transform 0.3s ease;
					}

					&:focus {
						outline: 1px dotted $red;
						outline-offset: 2px;
					}
				}
			}

			input {
				&[type="text"] {
					border: 2px solid $cool-white;
					color: $light-grey;
					display: inline-block;
					font-family: $primary;
					border-radius: 5px;

					&:focus {
						outline: none;
					}
				}
			}

			.site-search {
				background: rgba(white, 0.9);
				border: none;
				margin: 0;
				position: relative;
				width: 100%;
			}

			.yext-search-form {
				display: block;
			}

			button:not(.js-yext-submit) {
				display: none;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				padding: 0;
				width: 41px;

				&:focus {
					outline: 1px dotted $red;
					outline-offset: 2px;
				}
			}
		}
	}

	.cfa-one {
		z-index: 200;
		display: inline-block;
		margin: 0;
		position: relative;
		@include font-size(1.5);
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: 1.2;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		@include flex(0 0 auto);

		span,
		a {
			display: inline-block;
			line-height: inherit;
			border: 0;
		}

		@media only screen and (max-width: $sml-desktop) {
			white-space: nowrap;
		}

		@media only screen and (max-width: $mobile) {
			padding: 16px;
			@include align-items(center);
			@include justify-content(space-between);
			@include flexbox();
			margin: 0;
			// min-height: 200px;
			width: auto;
		}

		.username {
			margin: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
			max-width: 50%;

			@include break-min($large1) {
				white-space: nowrap;
				max-width: 150px;
				@include font-size(1.2);
			}
		}

		a:not(sup) {
			@include link-underline();
		}

		sup {
			@include font-size(0.8);
			top: -6px;
		}

		.sign-in {
			@include font-size(1.2);
			display: inline-block;
			line-height: inherit;
			vertical-align: middle;

			a,
			button {
				@include btn-to-link-conversion;
				display: inline-block;
				font-size: 12px;
				padding: 0 4px;
			}

			@media only screen and (max-width: $mobile) {
				button {
					@include font-size(1.4);
				}
			}
		}

		&.logged-in {
			.sign-in {
				display: block;
			}
		}

		&.logged-out {
			@extend .btn-secondary;
			padding: 0;

			.sign-in {
				button {
					padding: 14px 24px !important;

					@include break-min($large3) {
						padding: 14px 44px !important;
					}
				}
			}
		}
	}

	.order-online {
		z-index: 200;
		padding: 8px 16px;
		position: relative;

		@include break-min($large1) {
			padding: 0 20px;
			@include flex(0 0 auto);
		}

		a {
			display: block;
		}

		.btn-round {
			@include font-size(1.6);
		}

		a:focus {
			outline: 1px dotted $red;
		}
	}

	.order {
		position: relative;
		z-index: 200;

		.cart-toggle {
			color: $red;
			margin: 0;
			padding: 0;
			height: 50px;
			min-width: 30px;
			position: relative;
			border: 0;

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 2px;
			}

			&:hover {
				color: $red;
				cursor: pointer;
			}

			.cart-toggle-text {
				@include font-size(2.8);
				display: none;
				padding: 25px 16px;
			}

			&::before {
				content: none;
			}

			// burger
			&::after {
				content: "";
				background-image: url("img/icon_cart-bag.svg");
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 30px;
				height: 30px;
				transition: transform 0.3s ease;
				z-index: 100;
				margin-right: 0;
				margin-left: 0;
			}
		}

		.count {
			span {
				border-radius: 20px;
				color: $white;
				@include font-size(1.1);
				background-color: $red;
				font-family: $primary;
				letter-spacing: 0;
				line-height: 1.2;
				padding: 1px 4px;
				position: absolute;
				right: -5px;
				top: 4px;
				text-align: center;
				margin: 0;
				display: block;
				min-width: 15px;
				height: 15px;
				font-weight: $bold-weight;
				z-index: 300;
				box-shadow: 0px 0px 0px 1px white;
			}

			@include break-min($large1) {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}

	// nav shown overrides
	@media only screen and (max-width: $mobile) {
		.nav-shown & {
			.location-tools {
				display: block;
			}

			.cfa-one {
				// sass-lint:disable-block no-duplicate-properties
				display: block;
				@include flex-wrap(wrap);

				.username {
					max-width: 100%;
					padding: 0 0 8px;
					@include flex(1 0 100%);
				}
			}

			.order-online {
				float: left;
				width: 100%;
			}

			.order {
				padding: 13px 0;
				float: left;
				width: auto;
			}
		}
	}

	.subnav {
		background-color: $white;
		display: none;
		left: 0;
		padding: 18px 0;
		position: absolute;
		text-align: center;
		top: $nav-base-height;
		width: 100%;
		z-index: 100;

		@include break-min($large1) {
			// sass-lint:disable-block indentation
			box-shadow: inset 0 5px 4px -5px rgba(0, 0, 0, 0.2),
				0 5px 4px -5px rgba(0, 0, 0, 0.2);

			li {
				&:last-child {
					border-left: 1px solid $faint-grey;
					margin-left: 0;
					padding-left: 1.5%;
				}
			}
		}

		.about & {
			background-color: $white;
			transition: background-color 0.5s ease;
		}

		li {
			@include link-underline();
			margin: 0 1.5%;
			padding: 0;

			&:hover {
				transition-delay: 1s;

				& > a {
					@media only screen and (max-width: $mobile) {
						border-color: transparent;
					}

					&.food,
					&.lifestyle,
					&.cfa-stories,
					&.news {
						color: $red;

						&.current {
							border-color: $red;
						}

						@media only screen and (max-width: $mobile) {
							border-color: transparent;
						}
					}
				}

				.section {
					opacity: 1;
					transition: all 1.5s;
					z-index: 1000;
				}
			}

			h1 {
				font-size: 1.6rem;
				margin: 0;
			}
		}

		@media only screen and (max-width: $lrg-tablet) {
			padding-top: 30px;
		}

		@media only screen and (max-width: $mobile) {
			margin-top: 0;
			padding: 0;
			position: relative;
			top: 0;
			text-align: left;

			a {
				@include font-size(1.6);
				line-height: 28px;
				padding: 10px 15px 10px 20px;
			}
		}
	}

	.lock & {
		.subnav {
			.about & {
				background-color: $white;
				transition: background-color 0.5s ease;
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		li {
			display: block;
		}
	}
}

// CA Food Feature Section
.feature-card {
	margin: 0;
	padding: 20px 0;
	width: 100%;

	.flex {
		& > a.feature-card-container {
			@include font-size(1.6);
			border-radius: 8px;
			background-color: $warm-white;
			font-family: $primary;
			margin-left: $col-margin;
			text-align: center;
			height: 138px;
			width: $col6;
			display: flex;
			justify-content: center;
			flex-flow: column;
			@include font-size(1.6);
			@include link-underline();
			color: $red;
			font-weight: 600;
			line-height: 30px;

			@media only screen and (max-width: $mobile) {
				@include font-size(1.4);
				padding: 0;
			}

			@media only screen and (min-width: $lrg-tablet) {
				height: 160px;
			}

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				background-color: $white;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
				cursor: pointer;
			}
		}
	}
}

// Feature card icons

// Sandwich
.icon-food {
	border: 0;
	color: $red;
	position: relative;
	text-decoration: none;
	height: 52px;
	width: 76px;
	margin: 0 auto;

	@media only screen and (max-width: $mobile) {
		height: 43px;
		width: 64px;
	}

	&::after {
		content: "";
		background-image: url("img/icon_food.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
	}
}

// Tree nut
.icon-allergen {
	border: 0;
	color: $red;
	position: relative;
	text-decoration: none;
	height: 53px;
	width: 40px;
	margin: 0 auto;

	&::after {
		content: "";
		background-image: url("img/icon_tree-nut.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
	}
}

// Styles for CA Main Nav Mobile Menu
@media only screen and (max-width: $mobile) {
	.main-nav {
		padding-right: 0;
		padding-left: 0;

		& .subnav li {
			margin: 0;

			.icon-arrow-left {
				@include font-size(1.4);
				text-align: left;
				padding-right: 10px;

				&::before {
					display: inline-block;
					@include transform(rotate(90deg));
				}

				@media only screen and (min-width: $lrg-tablet) {
					display: none;
				}
			}

			&.mobile-only:first-child {
				background-color: $warm-white;
			}
		}
	}
}
